import { Disclosure } from '@headlessui/react'
import { ChevronUpIcon } from '@heroicons/react/solid'
import React from "react"

export default function MisOpciones() {
  return (
    <div className="w-full px-4 pt-1 ">
      <div className="w-full max-w-4xl p-2 mx-auto bg-white rounded-2xl ">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base font-medium text-left text-white bg-gray-800 rounded-lg hover:bg-red-900 focus:outline-none focus-visible:ring focus-visible:ring-zinc-100 focus-visible:ring-opacity-75">
                <span>Que aprenderé?</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
              Aprenderás trucos de magia para causar el mayor impacto en cualquier momento y lugar. Conocerás la <b>teoría</b> detras de la magia y <b>como manejar tu mirada, tu voz, tus manos, tu cuerpo y tus ángulos. </b>
              Te enseñaremos a sorprender con todo tipo de elementos: cartas, monedas, billetes, cigarrillos, anillos, fuego y mucho más.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
       
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base font-medium text-left text-white bg-gray-800 rounded-lg hover:bg-red-900 focus:outline-none focus-visible:ring focus-visible:ring-zinc-100 focus-visible:ring-opacity-75">
                <span>Conocimiento Previo?</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
              No se necesita ningún tipo de conocimiento previo. El Curso está orientado <b>para quienes quieran dar sus primeros pasos en el mundo de la magia</b> ya sea como hobby o de manera profesional.
              Hombres y Mujeres están invitados a adentrarse en el mundo del ilusionismo.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base font-medium text-left text-white bg-gray-800 rounded-lg hover:bg-red-900 focus:outline-none focus-visible:ring focus-visible:ring-zinc-100 focus-visible:ring-opacity-75">
                <span>Como se Divide el Curso?</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
              El curso de Magia se divide en <b>5 Secciones</b>, Aprendiz de la Magia, Mago Avanzado, Ilusionista, Arcano y una seccion Extra Sorpresa.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base font-medium text-left text-white bg-gray-800 rounded-lg hover:bg-red-900 focus:outline-none focus-visible:ring focus-visible:ring-zinc-100 focus-visible:ring-opacity-75">
                <span>Como puedo Acceder?</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
              En nuestra web podés comprar el curso presionando el boton rojo <b>"Inscribirme al Curso"</b> Podes realizar el pago via <b>Paypal</b>, si queres utilizar <b>Mercado Pago</b> contactanos al whats app.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base font-medium text-left text-white bg-gray-800 rounded-lg hover:bg-red-900 focus:outline-none focus-visible:ring focus-visible:ring-zinc-100 focus-visible:ring-opacity-75">
                <span>Entregan Diplomas?</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
              Sí, al finalizar el curso el alumno podra solicitar un Certificado estilo <b>Diploma</b>.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base font-medium text-left text-white bg-gray-800 rounded-lg hover:bg-red-900 focus:outline-none focus-visible:ring focus-visible:ring-zinc-100 focus-visible:ring-opacity-75">
                <span>Como es la plataforma?</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
              La plataforma es sumamente ordenada, prolija y eficiente, puede accederse desde cualquier dispositivo y se adapta <b>a la perfeccion</b>, entre sus funcionalidades podras descargar los videos e incluso verlos sin internet.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure as="div" className="mt-2">
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base font-medium text-left text-white bg-gray-800 rounded-lg hover:bg-red-900 focus:outline-none focus-visible:ring focus-visible:ring-zinc-100 focus-visible:ring-opacity-75">
                <span>Necesito Materiales?</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2 text-base text-gray-500">
              Unicamente una <b>baraja de naipes</b>, puedes adquirirla en nuestro local. A medida que avances en el curso iremos recomendando los articulos infaltables que todo mago posee. 
              Todos nuestros productos están en www.TrucosyMagia.com. Realizamos envíos.
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex justify-between w-full px-4 py-2 mt-2 text-base font-medium text-left text-white bg-gray-800 rounded-lg hover:bg-red-900 focus:outline-none focus-visible:ring focus-visible:ring-zinc-100 focus-visible:ring-opacity-75">
                <span>Hay un programa?</span>
                <ChevronUpIcon
                  className={`${
                    open ? 'transform rotate-180' : ''
                  } w-5 h-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-4 pt-4 pb-2  text-base text-gray-500">
              El programa es extenso, a continuacion  <strong>algunos ejemplos:</strong> 
              <ul>
                <li>- Posición de agarre de una baraja, <strong>como cortar, mezclar, buscar, distribuir y manejar cartas, florituras.</strong>  </li>
                <li>- Se desarrollarán los principios fundamentales por los cuales se rige el ilusionismo moderno. Ilusiones con <strong>naipes, monedas, papeles, control mental, explosiones, etc.</strong></li>
                <li>- Juegos de seducción, Teorías de la Magia - tipos de efecto, valorización, misdirection, psicología y mucho más. </li>
                <li>- Ilusionismo Avanzado. Psicología aplicada a los espectadores. Gags Mágicos. Efectos de <strong>adivinación de pensamiento, predicciones imposibles, transposiciones, mosquetas, multiplicación de billetes</strong> y mucho más. </li>
                <li>- Manejo de ángulos. Sincronización y Timing. Juegos de Salón y Magia de Cerca. Concepto de <strong>Tiempo Muerto, magia y gestualidad.</strong></li>
                <li>- Preparate a realizar tu primer juego de Nido.</li>
                <li>- <strong>Missdirection</strong> "El control de la atención". Practica este concepto ancestral de magia mientras te diviertes asombrando a las personas. Magia simbólica.</li>
                <li>- A prestidigitar! realiza movimientos imperceptibles con tus manos para deslumbrar a tu público. <strong>Pintajes</strong> - cambios de color más visuales de naipes.</li>
                <li>- <strong>Disparos de Naipes, controles avanzados, forzajes,</strong> la cuenta Geminis, cambios imperceptibles, preguntas obnuvilantes.</li>
              </ul>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

      </div>
    </div>
  )
}
