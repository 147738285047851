import React from "react"
const TresCajasGrid = () => {
    return (
        
        // <div className=" container flex gap-10 text-center justify-center  bg-white">
        <section className=""> 
          <div className="msm:hidden container rounded-md py-10 grid gap-10  grid-cols-3 text-center bg-[url('../images/cubiletes.jpg')] bg-cover   bg-no-repeat bg-center">
              
                  <div className="ml-4 bg-white rounded-lg py-10  px-2 ">
                  <h2 className="text-black uppercase text-3xl mb-2">Curso Ordenado </h2> 
                  <p className="text-black px-2     ">Crearás <strong>experiencias inolvidables</strong>. Primer y Único Curso de magia ordenado desde los inicios para el mejor aprendizaje. 
Estudia los secretos y tu mundo no volverá a ser el mismo...
                  </p>
                  </div>

                  <div className=" rounded-lg py-10 bg-white px-2 ">
                  <h2 className="text-black uppercase  text-3xl mb-2">Magia en Videos </h2> 
                  <p className="text-black px-2 ">Aprenderás magia de cerca, de salón y escena,  cartomagia, presentación mágica, <strong>psicología</strong>, 
                  magia humorística, mentalismo y manipulación. </p>
                  </div>
            
                  <div  className="mr-4 rounded-lg py-10 bg-white px-2   ">
                  <h2 className="text-black uppercase  text-3xl  mb-2 ">Mago Calificado </h2> 
                  <p className="text-black px-2 ">Aprende el maravilloso arte del Ilusionismo de la mano del <strong>Campeón Latinoamericano</strong>. <br/> <strong>15 años</strong> de experiencia dictando clases y cursos.</p>
                  </div>
          

          </div>
        </section>
    )
}

export default TresCajasGrid